.layout {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding:26px 56px 0 56px;
}

.layout > .header {
    flex: 1 0 auto;
}

.layout > .content {
    width: 98.5%;
}
